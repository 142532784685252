import { useApi } from '~/composables/api/useApi'

export function useLite() {
  const { fetchWithAuth } = useApi()
  const liteAPI = 'https://api-lite.transkribus.eu'

  // GET Requests

  async function fetchBookmarkModels(providedOptions: { userId: number }) {
    return await fetchWithAuth(
      `${liteAPI}/user-config/${providedOptions.userId}`,
      'GET'
    )
  }

  async function fetchReadShare(providedOptions: { hash: any }) {
    return await fetchWithAuth(
      `${liteAPI}/read-share/${providedOptions.hash}`,
      'GET',
      {}
    )
  }

  async function checkReadShare(providedOptions: { transcriptID: number }) {
    return await fetchWithAuth(
      `${liteAPI}/read-share/transcript/${providedOptions.transcriptID}`,
      'GET',
      {}
    )
  }

  // POST Requests

  async function updateBookmarkModels(providedOptions: {
    userId: number
    bookmarkedModels: any
  }) {
    return await fetchWithAuth(
      `${liteAPI}/user-config/${providedOptions.userId}`,
      'POST',
      {
        data: { bookmarkedModels: providedOptions.bookmarkedModels },
      }
    )
  }

  async function fetchWikidataIDs(providedOptions: { term: string }) {
    return await fetchWithAuth(`${liteAPI}/search/wikidata`, 'POST', {
      data: { term: providedOptions.term },
    })
  }

  async function createReadShare(providedOptions: {
    hash: any
    xml: any
    iiif: any
    transcriptID: number
    modelName: string
    userID: number
    title: string
  }) {
    return await fetchWithAuth(`${liteAPI}/read-share`, 'POST', {
      data: providedOptions,
    })
  }

  // DELETE Requests

  async function deleteReadShare(providedOptions: { transcriptID: number }) {
    return await fetchWithAuth(
      `${liteAPI}/read-share/transcript/${providedOptions.transcriptID}`,
      'DELETE',
      {}
    )
  }

  return {
    updateBookmarkModels,
    fetchBookmarkModels,
    createReadShare,
    fetchReadShare,
    checkReadShare,
    fetchWikidataIDs,
    deleteReadShare,
  }
}
